import { getCurrentBrowserFingerPrint } from '@rajesh896/broprint.js';
import * as Sentry from '@sentry/nextjs';

/**
 * Triggers a BroPrintJS fingerprint.
 *
 * This function returns an ID.
 *
 * If called outside of a browser environment, this function returns null.
 *
 * @returns {Promise<(String|null)>}
 */
export const getBrowserId = async (initialOptions = {}) => {
    // Fingerprint can only work in the browser; return null if we're not in
    // a browser environment
    if (!window) {
        return null;
    }
    try {
        const fp = await getCurrentBrowserFingerPrint();
        return fp.toString();
    } catch (err) {
        Sentry.captureException(err);
        return null;
    }
};
