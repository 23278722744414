export default class Link {
    #text;
    #url;

    constructor(text, url) {
        this.#text = text;
        this.#url = url;
    }

    get text() {
        return this.#text;
    }

    get url() {
        return this.#url;
    }
}
