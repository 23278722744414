import { useRouter } from 'next/router';
import { useCallback, useState } from 'react';
import { Offcanvas } from 'react-bootstrap';
import toast from 'react-hot-toast';

import CaseStudy from 'src/components/CaseStudy/CaseStudy.jsx';
import ContactContent from 'src/components/ContactContent/Contact.jsx';
import ContactForm from 'src/components/ContactForm/ContactForm.jsx';
import Footer from 'src/components/Footer/Footer.jsx';
import MetaTags from 'src/components/global/meta';
import Header from 'src/components/Header/Header.jsx';
import Link from 'src/components/Homepage/link';
import styles from 'src/components/Homepage/Homepage.module.scss';
import { LayoutContextProvider } from 'src/components/LayoutContext/LayoutContext';
import Navbar from 'src/components/Navbar/Navbar.jsx';
import Services from 'src/components/Services/Services.jsx';
import Showcase from 'src/components/Showcase/Showcase.jsx';
import Testimonials from 'src/components/Testimonials/Testimonials';
import Api from 'src/data/api';
import { trackEvent } from 'src/lib/analytics';
import { getBrowserId } from 'src/lib/fingerprint';

const useVisibleState = (defaultValue) => {
    const [visible, setVisible] = useState(defaultValue);
    const show = useCallback(() => setVisible(true), [setVisible]);
    const hide = useCallback(() => setVisible(false), [setVisible]);
    return [visible, show, hide];
};

const additionalLinks = [new Link('Blog', 'https://blog.witcontests.com')];

export default function Main() {
    const { asPath } = useRouter();

    const [contactFormVisible, showContactForm, hideContactForm] =
        useVisibleState();

    const handleSubmit = async ({ e, name, email, message, company }) => {
        if (e) {
            e.preventDefault();
        }
        const browserId = await getBrowserId();

        try {
            await Api.request({
                method: 'POST',
                url: `/contact`,
                data: {
                    name,
                    email,
                    message,
                    company,
                    browserId,
                    downloadId: null,
                    landingPage: asPath,
                },
            }).then(() => {
                toast.success('Message sent successfully!');
                hideContactForm();
                trackEvent('contact_message_submitted');
            });
        } catch (err) {
            toast.error('Message not sent. Please try again later.');
            console.error(err);
        }
    };

    return (
        <div className={styles.pageWrapper}>
            <MetaTags />
            <LayoutContextProvider>
                <Navbar
                    showContactForm={showContactForm}
                    links={additionalLinks}
                />
                <Header />
                <Showcase />
                <Testimonials />
                <CaseStudy />
                <Services />
                <ContactContent showContactForm={showContactForm} />
                <Footer showContactForm={showContactForm} />

                <Offcanvas
                    show={contactFormVisible}
                    onHide={hideContactForm}
                    placement="end"
                    className={styles.offCanvas}
                >
                    <Offcanvas.Header
                        closeButton
                        className={styles.offCanvasHeader}
                    >
                        <ContactForm
                            onSubmit={handleSubmit}
                            hideContactForm={hideContactForm}
                            type="home"
                            links={additionalLinks}
                        />
                    </Offcanvas.Header>
                </Offcanvas>
            </LayoutContextProvider>
        </div>
    );
}
