export default function ServicesBanner({ text }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1700 150.001">
            <defs>
                <linearGradient
                    id="services-banner-linear-gradient"
                    x1="1"
                    x2="0"
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset="0" stopColor="#03ffff" />
                    <stop offset="1" stopColor="#2d8aea" />
                </linearGradient>
                <clipPath id="services-banner-clip-path">
                    <path
                        d="M-620,150H-2158.336l150-150H-620V150Zm-1566.621,0h0l-62,0,150-150h62Zm-104.431,0h0L-2320,150V131.086L-2188.914,0h47.861ZM-2320,74.516h0l0,0V40.8L-2279.2,0h33.72Z"
                        transform="translate(-400 2875.002) rotate(180)"
                        fill="#fff"
                    />
                </clipPath>
            </defs>
            <g id="Ribbon" transform="translate(-220 -2725)">
                <path
                    d="M-620,150H-2158.336l150-150H-620V150Zm-1566.621,0h0l-62,0,150-150h62Zm-104.431,0h0L-2320,150V131.086L-2188.914,0h47.861ZM-2320,74.516h0l0,0V40.8L-2279.2,0h33.72Z"
                    transform="translate(-400 2875.002) rotate(180)"
                    fill="url(#services-banner-linear-gradient)"
                />
                <g clipPath="url(#services-banner-clip-path)">
                    <text
                        transform="translate(232 2840)"
                        fill="#1a1a1a"
                        fontSize="112"
                        fontFamily="Kanit-ExtraBoldItalic, Kanit"
                        fontWeight="800"
                        fontStyle="italic"
                    >
                        <tspan x="0" y="0">
                            {text}
                        </tspan>
                    </text>
                </g>
            </g>
        </svg>
    );
}
