import Head from 'next/head';
import Homepage from 'src/components/Homepage';

export default function HomePage() {
    return (
        <>
            <Head>
                <title>
                    WIT | #1 Digital Activation Platform | Capture First-Party
                    Data
                </title>
            </Head>
            <Homepage />
        </>
    );
}
