import styles from './CaseStudy.module.scss';

function Item({ value, valuePrefix = '', title }) {
    return (
        <div className={styles.caseStudyBannerItem}>
            <div className={styles.itemValueWrapper}>
                {!!valuePrefix && (
                    <span className={styles.itemValuePrefix}>
                        {valuePrefix}
                    </span>
                )}
                <span className={styles.itemValue}>{value}</span>
            </div>

            <p className={styles.itemTitle}>{title}</p>
        </div>
    );
}

export default function CaseStudyBanner({ items }) {
    return (
        <div className={styles.caseStudyBanner}>
            {items.map((item, i) => (
                <Item key={i} {...item} />
            ))}
        </div>
    );
}
