import Quotes from 'public/assets/quotes.svg';
import { Carousel } from 'react-bootstrap';
import Image from 'src/components/global/cloudflareImage';
import styles from 'src/components/Testimonials/Testimonials.module.scss';

const testimonialData = [
    {
        image: 'https://s3.amazonaws.com/wit-challenge-assets-dev/branding/demo-8286610c-95c2-4ebb-a90d-637ba097c502.jpg',
        clientName: 'Brent Rossi',
        clientTitle: 'SVP, Marketing & Media',
        companyName: 'Cleveland Browns',
        text: 'We entered our partnership with WIT with big plans and even bigger ideas. From the very first meeting, Vaidhy and his team ensured they were not only capable of executing our activations to the fullest, but were also equipped to make them even bigger and better than anticipated. WIT isn’t your typical vendor that just takes and executes requests. They immerse themselves in every project and, most importantly, deliver results.',
    },
    {
        image: 'https://s3.amazonaws.com/wit-challenge-assets-dev/branding/demo-1e314c75-53e6-4286-ac22-40098f66e532.jpg',
        clientName: 'Dan LaTorraca',
        clientTitle: 'VP of Marketing & Brand Strategy',
        companyName: 'Carolina Hurricanes',
        text: 'WIT has been an extremely valuable and important part of our 25th Anniversary campaign - and they have operated as an extension of our digital team, ensuring that every activation we produce is constructed efficiently and yields as much impact as possible. They kicked our season off with a custom build – a tool that has allowed thousands of our fans to vote on the Carolina Hurricanes 25th Anniversary Team, all while generating qualified leads we can capitalize on in the future. WIT has a great approach for sports teams and their tools allow for the perfect mix of fan engagement and revenue-generation opportunities. Every sports team – from the minors to the pros – should be working with them, the possibilities are limitless AND they make it easy to integrate unique and engaging lead-gen opportunities into any campaign.',
        fontSize: '1.3em',
        padding: '35px',
    },
    {
        image: 'https://s3.amazonaws.com/wit-web-assets/brand-demos/nfl_logo.png',
        clientName: 'Aaron Jones',
        clientTitle: 'Director, Performance Marketing & Strategy',
        companyName: 'National Football League',
        text: 'Working with the WIT team has elevated our 2022 digital playbook to a whole new level. WIT combines engaging, fan-centric activations with 1st party data capture to create unforgettable brand experiences. They have been fantastic partners in this journey and have been instrumental in helping to bring our vision to life. We have seen marked growth in opted-in fan data capture and many new meaningful connections formed with our fans.',
    },
    {
        image: 'https://s3.amazonaws.com/wit-challenge-assets-dev/branding/demo-388382ba-70df-4e98-aa3c-692bb9f04fdd.jpg',
        clientName: 'Brett Sowell',
        clientTitle: 'Director, Digital Strategy and Monetization',
        companyName: 'Playfly Sports Properties',
        text: 'Our sponsorship sales team has been very pleased with WIT’s activation assortment options, campaign performance, and client service. Many of our brand pitches and activations are time sensitive and WIT exceeds expectations in their responsiveness to our needs. The WIT team’s focus on client success is very much appreciated throughout our organization.',
    },
    {
        image: 'https://s3.amazonaws.com/wit-challenge-assets-dev/branding/demo-3060aef3-07b1-4f37-88ee-1131a89ce7f0.jpg',
        clientName: 'Nico Ruggiero',
        clientTitle: 'Digital Marketing Manager',
        companyName: 'Buffalo Sabres',
        text: 'Working with WIT has helped take our digital sweepstakes and activations to a new level. We have seen marked growth in both engagement and opt-in rate, helping to grow our database and build meaningful new connections with our fans. The WIT team have also been instrumental in helping brainstorm and execute exciting custom contests that our fans have loved.',
    },
    {
        image: 'https://wit-challenge-assets-prod.s3.amazonaws.com/branding/18-a7a47e54-acb7-4fea-8970-5bc757e4548b.png',
        clientName: 'Brandon Jirousek',
        clientTitle: 'VP of Digital',
        companyName: 'Cleveland Cavaliers',
        text: "Our relationship began with the WIT team ahead of the 2022 NBA All-Star game in Cleveland, and we could not be more excited as the partnership continues to grow. By delivering highly engaging and interactive digital experiences that support our team's ability to capture 1st party data, we have developed more meaningful connections with our fans across our site and mobile app. Vaidhy and his team excel in this space with their attention to detail and client success, consistently meeting expectations and delivering significant results.",
        fontSize: '1.5em',
        padding: '35px',
    },
];

const Testimonial = ({
    image,
    clientName,
    clientTitle,
    companyName,
    text,
    fontSize,
    padding,
}) => {
    return (
        <div
            className={styles.testimonial}
            style={{ paddingLeft: padding, paddingRight: padding }}
        >
            <div className={styles.quoteWrapper}>
                <div className={styles.quotationMarks}>
                    <Quotes aria-hidden="true" />
                    <span className="visually-hidden">"</span>
                </div>

                <p style={{ fontSize: fontSize }}>{text}</p>
            </div>
            <div className={styles.logoAndClientWrapper}>
                <div className={styles.imageWrapper}>
                    <Image
                        src={image}
                        alt={`${clientTitle} Testimonial`}
                        layout="fill"
                        objectFit="contain"
                        quality={80}
                    />
                </div>
                <div className={styles.clientCopy}>
                    <h1>{clientName}</h1>
                    <div className={styles.company}>
                        {clientTitle} <span>{companyName}</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default function Testimonials() {
    return (
        <div className={styles.sectionWrapper}>
            <div className={styles.header}>
                <h1>THE RESULTS ARE IN</h1>
                <p>
                    For us, victory looks like sky-high engagement, mega-boosted
                    leads, and unforgettable experiences. Our number one goal is
                    making our clients and partners look good, and it seems to
                    be paying off. See what others have to say about working
                    with WIT.
                </p>
            </div>
            <Carousel className={styles.carousel} controls={false}>
                {testimonialData.map((testimonial, index) => (
                    <Carousel.Item key={index}>
                        <Testimonial {...testimonial} />
                    </Carousel.Item>
                ))}
            </Carousel>
        </div>
    );
}
