import TextBubbles from 'public/icons/textBubbles.svg';
import { Button } from 'react-bootstrap';

import styles from './Contact.module.scss';

export default function ContactContent({ showContactForm }) {
    return (
        <div className={styles.sectionWrapper}>
            <div className={styles.line} />

            <TextBubbles />
            <div className={styles.header}>
                Let's <br />
                <span>Talk</span>
            </div>
            <div className={styles.description}>
                <p>
                    Your team is crushing it IRL, but are you fumbling when it
                    comes to digital engagement? Contact us to find out how we
                    can help you crush your goals.
                </p>
                <Button
                    className={styles.contactButton}
                    onClick={showContactForm}
                >
                    Contact
                </Button>
            </div>

            <div className={styles.background} />
        </div>
    );
}
