import { Col, Row } from 'react-bootstrap';
import Image from 'src/components/global/cloudflareImage';

import styles from './Services.module.scss';
import ServicesBanner from './ServicesBanner';

function Service({ image, header, children }) {
    return (
        <div className={styles.serviceWrapper}>
            <div className={styles.serviceImage}>
                <Image
                    src={image}
                    layout="fill"
                    objectFit="cover"
                    quality={80}
                />
            </div>
            <div className={styles.contentWrapper}>
                <div className={styles.header}>{header}</div>
                <div className={styles.text}>{children}</div>
            </div>
        </div>
    );
}

export default function Services() {
    return (
        <div className={styles.sectionWrapper}>
            <div className={styles.mainHeader}>
                <h1>The Future is Reve-New</h1>
                <p>WIT builds your triple threat capabilities with:</p>
            </div>
            <Row className={styles.services}>
                <Col md={4}>
                    <Service
                        image="https://wit-web-assets.s3.amazonaws.com/website/partnerships.png"
                        header="Winning Partnerships"
                    >
                        Easy partner integrations and turnkey experiences
                        increase your digital activation inventory and deliver
                        cutting-edge engagements that thrill fans and keep your
                        partners coming back for more.
                    </Service>
                </Col>

                <Col md={4}>
                    <Service
                        image="https://wit-web-assets.s3.amazonaws.com/website/marketing.png"
                        header="Show-Stopping Marketing"
                    >
                        Seize the moment and create viral opportunities with a
                        full suite of activation products that lets you unlock
                        the power of user-generated content, innovative
                        engagements, and incentivized sharing.
                    </Service>
                </Col>

                <Col md={4}>
                    <Service
                        image="https://wit-web-assets.s3.amazonaws.com/website/sales.png"
                        header="Sales That Won't Quit"
                    >
                        Generate qualified leads and drive revenue with better
                        data that lets you gain unprecedented ongoing insights
                        into both superfans and passive fans—so you can elevate
                        loyalty and boost sales.
                    </Service>
                </Col>
            </Row>

            <div className={styles.banner}>
                <ServicesBanner text="DOMINATE THE FIELD" />
            </div>
        </div>
    );
}
