import classnames from 'classnames';
import { useState } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';

import styles from 'src/components/ContactForm/ContactForm.module.scss';
import Logo from 'public/assets/logo.svg';

const setWithTargetValue = (setter) => (e) => setter(e.target.value);

export default function ContactForm({
    type = 'home',
    links = [],
    downloadInfo,
    onSubmit,
}) {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [company, setCompany] = useState('');

    return (
        <div
            className={classnames(styles.sectionWrapper, {
                [styles.modalForm]: type === 'mediacenter',
            })}
        >
            <div className={styles.headerDesktop}>
                <div className={styles.logo}>
                    <Logo />
                </div>
            </div>

            <div className={styles.linksContainer}>
                {links.map((link) => (
                    <a
                        href={link.url}
                        key={`link-${link.text}`}
                        className={styles.link}
                    >
                        {link.text}
                    </a>
                ))}
            </div>

            <div className={styles.header}>Let's Talk</div>
            <div className={styles.subHeader}>
                Your team is crushing it IRL, but are you fumbling when it comes
                to digital engagement? Contact us to find out how we can help
                you crush your goals.
            </div>

            <Form
                onSubmit={(e) =>
                    onSubmit({
                        e,
                        name,
                        email,
                        message,
                        downloadInfo,
                        company,
                    })
                }
                className={styles.contactForm}
            >
                <Form.Label htmlFor="name">Name</Form.Label>
                <InputGroup>
                    <Form.Control
                        required
                        id="name"
                        type="text"
                        value={name}
                        onChange={setWithTargetValue(setName)}
                    />
                </InputGroup>

                <Form.Label htmlFor="email">Email</Form.Label>
                <InputGroup>
                    <Form.Control
                        required
                        id="email"
                        type="email"
                        value={email}
                        onChange={setWithTargetValue(setEmail)}
                    />
                </InputGroup>

                <Form.Label htmlFor="company">
                    Company Name &nbsp;<span>(Optional)</span>
                </Form.Label>
                <InputGroup>
                    <Form.Control
                        id="company"
                        type="text"
                        value={company}
                        onChange={setWithTargetValue(setCompany)}
                    />
                </InputGroup>

                <Form.Label htmlFor="message">
                    Message &nbsp;<span>(Optional)</span>
                </Form.Label>
                <InputGroup>
                    <Form.Control
                        id="message"
                        as="textarea"
                        value={message}
                        onChange={setWithTargetValue(setMessage)}
                    />
                </InputGroup>

                <Button type="submit" disabled={!name || !email}>
                    Submit
                </Button>
            </Form>
        </div>
    );
}
