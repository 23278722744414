import { useEffect } from 'react';
import Image from 'src/components/global/cloudflareImage';

import logoBrandsDesktop from 'public/assets/logo_brands.png';
import logoBrandsMobile from 'public/assets/logo_brands_mobile.png';
import PhoneBackground from 'public/assets/phoneBackground.svg';
import PhoneOutline from 'public/assets/phoneOutline.svg';
import { useMediaQuery } from 'react-responsive';
import { useLayoutContext } from '../LayoutContext/LayoutContext';
import styles from './Showcase.module.scss';
import ShowcaseBanner from './ShowcaseBanner';

function Phone({ screen }) {
    return (
        <div className={styles.phoneImageWrapper}>
            <PhoneOutline />
            <div className={styles.phoneScreen}>
                <Image
                    src={screen}
                    layout="fill"
                    objectFit="contain"
                    quality={80}
                />
            </div>
            <PhoneBackground />
        </div>
    );
}

export default function Showcase() {
    const {
        decorativeLineRef,
        headerWrapperRef,
        headerCopyWrapperRef,
        showcaseBackgroundRef: backgroundRef,
        showcaseFirstHeadingRef: firstHeadingRef,
    } = useLayoutContext();

    useEffect(() => {
        function handleResize() {
            if (backgroundRef.current && firstHeadingRef.current) {
                const { offsetTop, offsetHeight } = firstHeadingRef.current;
                const newTop = `${offsetTop + offsetHeight}px`;
                backgroundRef.current.style.top = newTop;

                if (decorativeLineRef.current) {
                    decorativeLineRef.current.style.height = `${
                        offsetTop +
                        headerWrapperRef.current?.offsetHeight -
                        headerCopyWrapperRef.current?.offsetTop -
                        (window.innerWidth <= 576 ? 50 : 10)
                    }px`;
                }
            }
        }

        handleResize();
        window.addEventListener('resize', handleResize, false);
        return () => window.removeEventListener('resize', handleResize, false);
    }, [
        firstHeadingRef,
        backgroundRef,
        decorativeLineRef,
        headerWrapperRef,
        headerCopyWrapperRef,
    ]);

    const isMobile = useMediaQuery({ query: '(max-width: 767.98px)' });
    const logoBrands = isMobile ? logoBrandsMobile : logoBrandsDesktop;
    return (
        <div className={styles.sectionWrapper}>
            <div className={styles.background} ref={backgroundRef}></div>

            <div className={styles.activationExamples}>
                <div className={styles.phonesWrapper}>
                    <Phone
                        screen={
                            'https://wit-web-assets.s3.amazonaws.com/website/browns_phone.png'
                        }
                    />
                    <Phone
                        screen={
                            'https://wit-web-assets.s3.amazonaws.com/website/iwg.png'
                        }
                    />
                </div>

                <div className={styles.copy}>
                    <h1 ref={firstHeadingRef}>
                        MEET YOUR NEW
                        <br /> DIGITAL MVP
                    </h1>
                    <p>
                        Push the boundaries of engagement with supercharged
                        digital experiences that bring you closer to fans than
                        ever before. With turnkey giveaways, contests, polls,
                        partner integrations, games, and everything in between,
                        WIT takes your marketing game to the next level.
                    </p>
                </div>
            </div>

            <div className={styles.brandExamples}>
                <div className={styles.copy}>
                    <h1>ROLL WIT THE BEST</h1>
                    <p>
                        It's no coincidence that our winner's circle includes
                        the world's most exciting brands. WIT makes it easy to
                        create game-changing experiences that keep fans on the
                        edge of their seats.
                    </p>
                </div>

                <div className={styles.brandLogos}>
                    <Image src={logoBrands} />
                </div>
            </div>

            <div className={styles.banner}>
                <ShowcaseBanner text="BEST-IN-CLASS ACTIVATIONS" />
            </div>
        </div>
    );
}
