import Image from 'src/components/global/cloudflareImage';
import CaseStudyBanner from './CaseStudyBanner';

import Computer from 'public/assets/computer.svg';

import styles from 'src/components/CaseStudy/CaseStudy.module.scss';

export default function CaseStudy() {
    return (
        <div className={styles.sectionWrapper}>
            <div className={styles.caseStudyWrapper}>
                <div className={styles.imageWrapper}>
                    <Computer />
                    <div className={styles.computerScreen}>
                        <Image
                            src={
                                'https://wit-web-assets.s3.amazonaws.com/website/computerScreen.png'
                            }
                            layout="fill"
                            objectFit="contain"
                        />
                    </div>
                </div>

                <div className={styles.caseStudyCopy}>
                    <h3>Featured Case Study</h3>
                    <h1>
                        My Cause My <br /> Cleats
                    </h1>
                    <p>
                        The best way to drive engagement through the roof? Start
                        with their feet. Thanks to our innovative platform and
                        custom partnership integrations, NFL fans were able to
                        create their own “caused out” cleats featuring their
                        favorite team and charity.&nbsp;
                        <span>
                            <a
                                href="https://try.witcontests.com/nfl/survey/my-cause-my-cleats"
                                target="_blank"
                            >
                                Learn More
                            </a>
                        </span>
                    </p>
                </div>
            </div>

            <CaseStudyBanner
                items={[
                    { value: 'Millions', title: 'Of Impressions' },
                    { value: '32', title: 'Teams' },
                    {
                        value: '6',
                        title: 'Causes',
                    },
                    { value: 'Thousands', title: 'Of Caused Out Cleats' },
                ]}
            />
        </div>
    );
}
