import axios from 'axios';
import toast from 'react-hot-toast';

class Api {
    constructor() {
        this.client = axios.create({
            baseURL: CONFIG.apiUrl,
        });

        this.client.interceptors.response.use(null, (err) => {
            console.error(err.response);
            toast.error(
                err.response?.data?.error ||
                    err.response?.data?.message ||
                    err.message ||
                    'An unexpected error occurred. Please try again.'
            );
            return Promise.reject(err);
        });
    }

    request(config) {
        const requestConfig = { ...config };
        if (CONFIG.debug) {
            console.log(
                `axios: ${requestConfig?.method || 'GET'} ${requestConfig.url}`
            );
        }

        return this.client.request(requestConfig);
    }
}

export default new Api();
