import WinWithWit from 'public/assets/winWithWit.svg';
import Basketball from 'public/icons/basketball.svg';
import Megaphone from 'public/icons/megaphone.svg';
import Pricetag from 'public/icons/pricetag.svg';

import { useLayoutContext } from '../LayoutContext/LayoutContext';
import styles from './Header.module.scss';

export default function Header() {
    const { decorativeLineRef, headerWrapperRef, headerCopyWrapperRef } =
        useLayoutContext();

    return (
        <div className={styles.sectionWrapper} ref={headerWrapperRef}>
            <div className={styles.winWithWit}>
                <WinWithWit />
            </div>

            <div className={styles.background}></div>

            <div className={styles.mainCopyWrapper} ref={headerCopyWrapperRef}>
                <div className={styles.mainLogosWrapper}>
                    <Basketball />
                    <Pricetag />
                    <Megaphone />
                </div>
                <div
                    className={styles.decorativeLineVertical}
                    ref={decorativeLineRef}
                >
                    <div className={styles.decorativeLineHorizontal}></div>
                </div>

                <div className={styles.description}>
                    <p>
                        WIT is the best-in-class digital activation platform that
                        powers some of the most exciting, turnkey experiences in sports and entertainment.
                    </p>
                </div>
            </div>
        </div>
    );
}
